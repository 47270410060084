import React from 'react';
import { NavLink } from 'react-router-dom';
import mainNavigation from '../../routes/mainNavigation';
import * as styles from './style.module.css';
import { useSelector } from 'react-redux';
import { getLoggedUserSelector } from '@hero/redux-data/backoffice/userStatus/selectors';
import Logo from '@hero/branding/logo';
import Logout from '../../pages/Home/Logout';
import MainSearch from '../MainSearch';

const Navigation: React.FC = () => {
    const user = useSelector(getLoggedUserSelector);
    const userStatus = user?.status || {};
    const itemsFiltered = (mainNavigation || []).filter((item) => {
        if (!item.roles) return true;
        //@ts-ignore
        return item.roles.some((role) => !!userStatus[role]);
    });

    const downLinks = ['Payor A Sign Up', 'Orders', 'Monitoring'];

    const mainNav = itemsFiltered.filter((item) => !downLinks.includes(item.name));
    const lowerNav = itemsFiltered.filter((item) => downLinks.includes(item.name));

    return (
        <>
            <div className={styles.container}>
                <nav className={styles.navbar}>
                    <div className={styles.leftSection}>
                        <Logo styles={{ marginRight: '10px' }} />

                        {mainNav.map((item, index) => (
                            <NavLink
                                className={styles.link}
                                key={index}
                                style={(navData) => (navData.isActive ? { color: '#F26C3A' } : {})}
                                to={item.to}
                            >
                                {item.name}
                            </NavLink>
                        ))}
                    </div>
                    <div className={styles.rightSection}>
                        <MainSearch
                            className={styles.search}
                            searchText="Enter email / serial # / external id"
                        />

                        <Logout />
                    </div>
                </nav>
            </div>
            <nav style={{ paddingLeft: '78px' }}>
                {lowerNav.map((item, index) => (
                    <NavLink
                        className={styles.lowerLink}
                        key={index}
                        style={(navData) => (navData.isActive ? { color: '#F26C3A' } : {})}
                        to={item.to}
                    >
                        {item.name}
                    </NavLink>
                ))}
            </nav>
        </>
    );
};

export default Navigation;
