import Cookie from '@hero/api-services/utils/cookie';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetDischargedMembersCSV = () => {
    const additional = useQueryRequestParams();
    const CF_Authorization = Cookie.getCookie('CF_Authorization');
    const downloadMembersCsv = (name) => {
        const url = `${envVars.API_COMMERCE_HOSTNAME}organizations/backoffice/discharge/download`;
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Authorization', `${additional.headers?.Authorization}`);
        xhr.setRequestHeader('Cf-Access-Jwt-Assertion', `${CF_Authorization}`);
        xhr.onload = function () {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);
            var tag = document.createElement('a');
            tag.href = imageUrl;
            tag.download = name || 'discharged_members_list.csv';
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        };
        xhr.send();
    };
    return downloadMembersCsv;
};
export default useGetDischargedMembersCSV;
