import React, { FC, useMemo } from 'react';
import Container from '@hero/ui-kit/layout/Container';
import Span from '@hero/ui-kit/typography/Span';
import useTheme from '@hero/branding/theme';
import getActiveDateFormatted from '../../../../utils/getActiveDateFormatted';
import H from '@hero/ui-kit/typography/H';
import { useRmaContext } from '../context/RmaProvider';
import formatDollarAmount from '@hero/hero-utils/currency';
import envVars from '../../../../constants/envVars';
import { preparedOptionsValue, preparedValue } from '../../utils/prepareValue';
import RestockingCheck from '../../components/RestockingCheck';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import Divider from '@hero/ui-kit/components/Divider';
import P from '@hero/ui-kit/typography/P';

interface CancellationProps {}

export const Cancellation: FC<CancellationProps> = () => {
    const { colors } = useTheme();

    const {
        rma,
        leftColWrapStyles,
        labelStyle,
        valueStyle,
        rmaReasons,
        rmaStatuses,
        formatLocalDateTime,
        returnAddress,
        returnLabelLink,
        rightColWrapStyles,
        returnLocations,
        isOrganizationMember,
        trackingNumber,
        zendeskTicketNumber,
        orderEmail,
        activatedAt,
        returnStatuses,
        returnedDeviceState,
        replacementBoxRequired,
        replacementBoxTrackingNumber,
        cancellationDetails,
        orgCancellationDetails
    } = useRmaContext();

    const cancellationData = isOrganizationMember
        ? orgCancellationDetails?.cancellation
        : cancellationDetails?.cancellation;

    const {
        id,
        rma_status,
        customer_name,
        reason_code,
        reason_sub_code,
        created_at,
        comments,
        return_location,
        reprocessing_check,
        device_serial,
        last_updated_at,
        return_status
    } = rma ?? {};

    const refundChargeAmount = useMemo(() => {
        const totalRefund = cancellationData?.refund_amount;
        const totalRefundFormatted =
            typeof totalRefund === 'number' ? formatDollarAmount(totalRefund, true) : '-';

        const totalFee = cancellationData?.cancellation_fee?.amount;
        const totalFeeFormatted =
            typeof totalFee === 'number' ? formatDollarAmount(totalFee, true) : '-';

        if (totalRefund) {
            return `${totalRefundFormatted} Refunded`;
        }
        if (totalFee) {
            return `${totalFeeFormatted} Charged`;
        }
        return '-';
    }, [cancellationData]);

    const paddingBottom = useComponentPadding({ paddingBottom: 'small' });

    return (
        <>
            <H role="h4" centered>
                RMA Detail
            </H>

            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular" gridRowGap="regular">
                <Container styles={leftColWrapStyles}>
                    <P styles={{ ...labelStyle, marginBottom: 0 }} noDefaultMargin>
                        RMA Status
                    </P>
                    <P styles={valueStyle}>{preparedOptionsValue(rma_status, rmaStatuses)}</P>
                </Container>

                <Container styles={{ ...rightColWrapStyles, gridRowStart: 0 }}>
                    <P styles={{ ...labelStyle, marginBottom: 0 }} noDefaultMargin>
                        Created by
                    </P>
                    <P styles={valueStyle}>{rma?.created_by || 'N/A'}</P>
                </Container>
            </Container>

            <P styles={{ ...labelStyle, marginBottom: 0 }} noDefaultMargin>
                Returned Device State
            </P>
            <P styles={{ ...valueStyle, marginBottom: '4.8rem' }}>{returnedDeviceState}</P>
            <Container
                styles={{ ...paddingBottom }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                gridRowGap="regular"
            >
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Plan Active Date</Span>
                    <Span styles={valueStyle}>{getActiveDateFormatted(activatedAt)}</Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Date Created</Span>
                    <Span styles={valueStyle}>{formatLocalDateTime(created_at)}</Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Member</Span>
                    <Span styles={valueStyle}>{preparedValue(customer_name)}</Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Cancellation Type</Span>
                    <Span styles={valueStyle}>
                        {cancellationData?.cancellation_type
                            ? `${cancellationData?.cancellation_type.name}: ${cancellationData?.cancellation_type.description}`
                            : '-'}
                    </Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Reason for Cancellation</Span>
                    <Span styles={valueStyle}>
                        {reason_code && rmaReasons ? rmaReasons[reason_code].description : '-'}
                    </Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Device Serial</Span>
                    <Span styles={valueStyle}>{preparedValue(device_serial)}</Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Zendesk Ticket</Span>
                    {zendeskTicketNumber}
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 1 }}>
                    <Span styles={labelStyle}>RMA #</Span>
                    <Span styles={valueStyle}>{preparedValue(id)}</Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 2 }}>
                    <Span styles={labelStyle}>Last Updated At</Span>
                    <Span styles={valueStyle}>{formatLocalDateTime(last_updated_at)}</Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 3 }}>
                    <Span styles={labelStyle}>Email Address</Span>
                    <Span styles={valueStyle}>{preparedValue(orderEmail)}</Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 4 }}>
                    <Span styles={labelStyle}>Sub Reason</Span>
                    <Span styles={valueStyle}>
                        {reason_sub_code && rmaReasons
                            ? rmaReasons[reason_code || 0].sub_codes[reason_sub_code]
                            : '-'}
                    </Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 5 }}>
                    <Span styles={labelStyle}>Refund/Charge</Span>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            ...valueStyle,
                            color: colors.brand.primary.alpha,
                            textDecoration: 'underline'
                        }}
                        href={`${envVars.STRIPE_DASHBOARD_BASE_URL}/customers`}
                    >
                        {refundChargeAmount}
                    </a>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 6 }}>
                    <Span styles={labelStyle}>Restocking check</Span>
                    <RestockingCheck
                        deviceSerial={device_serial}
                        reprocessingCheck={reprocessing_check}
                    />
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 7 }}>
                    <Span styles={labelStyle}>Notes</Span>
                    <Span styles={valueStyle}>{preparedValue(comments)}</Span>
                </Container>
            </Container>
            <Divider styles={{ borderColor: colors.brand.primary.alpha }} />
            <Container
                styles={{ ...paddingBottom }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                gridRowGap="regular"
            >
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Replacement Box Required</Span>
                    <Span styles={valueStyle}>{replacementBoxRequired}</Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 1 }}>
                    <Span styles={labelStyle}>Replacement Box Tracking Number</Span>
                    {replacementBoxTrackingNumber}
                </Container>
            </Container>
            <Divider styles={{ borderColor: colors.brand.primary.alpha }} />
            <Container
                styles={{ ...paddingBottom }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                gridRowGap="regular"
            >
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Return Tracking #</Span>
                    {trackingNumber}
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Return Status</Span>
                    <Span styles={valueStyle}>
                        {preparedOptionsValue(return_status, returnStatuses)}
                    </Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Return Label</Span>
                    {returnLabelLink}
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 1 }}>
                    <Span styles={labelStyle}>Return Location</Span>
                    <Span styles={valueStyle}>
                        {preparedOptionsValue(return_location, returnLocations)}
                    </Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 2 }}>
                    <Span styles={labelStyle}>Return Address</Span>
                    {returnAddress}
                </Container>
            </Container>
        </>
    );
};
