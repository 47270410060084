import React, { CSSProperties, useCallback } from 'react';
import Modal from '@hero/ui-kit/components/Modal';
import Section from '@hero/ui-kit/layout/Section';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import TextArea from '@hero/ui-kit/inputs/TextArea';
import H from '@hero/ui-kit/typography/H';
import { DischargeMember } from '../../types';
import P from '@hero/ui-kit/typography/P';
import MemberNotesSchema, { MemberNotesParams } from './schema';
import { formatDateTime, normalizeDateString } from '@hero/hero-utils/date';

type Props = {
    onConfirm: (member: DischargeMember, notes: string) => void;
    onCancel: () => void;
    member?: DischargeMember | null;
    disabled?: boolean;
};

type FormElementsProps = {
    onCancel: () => void;
    disabled?: boolean;
};

const FormElements: React.FC<FormElementsProps> = ({ onCancel, disabled }) => {
    const {
        reset,
        formState: { isSubmitSuccessful }
    } = useFormContext();

    const innerWrapperStyles: CSSProperties = {
        width: '48rem'
    };

    React.useEffect(() => {
        isSubmitSuccessful &&
            reset({
                notes: ''
            });
    }, [isSubmitSuccessful]);
    return (
        <Section noDefaultPadding centered styles={innerWrapperStyles}>
            <TextArea name="notes" displayName="New Note" rows={6} resize="none" />
            <div style={{ marginTop: '1.2rem' }}>
                <Button disabled={disabled} width="large" type="submit">
                    Save
                </Button>
                <Button disabled={disabled} width="large" variant="secondary" onClick={onCancel}>
                    Cancel
                </Button>
            </div>
        </Section>
    );
};

const MemberNoteModal: React.FC<Props> = ({ onConfirm, onCancel, member, disabled }) => {
    const notes = member?.notes || [];

    const handleConfirm = useCallback(
        ({ notes }: MemberNotesParams) => {
            member && onConfirm(member, notes);
        },
        [onConfirm, member]
    );

    return member ? (
        <Modal onClose={onCancel} isCancelable styles={{ padding: 0 }}>
            <H role="h4" centered>
                MX Notes
            </H>
            <div
                style={{
                    overflowY: 'auto',
                    maxHeight: '24rem',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    maxWidth: '54rem'
                }}
            >
                {notes
                    .sort((a, b) => {
                        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                    })
                    .map((note) => {
                        return (
                            <div key={note.created_at} style={{ width: '100%' }}>
                                <P>
                                    <strong>{note.full_name}</strong> •{' '}
                                    {note.created_at
                                        ? formatDateTime(normalizeDateString(note.created_at))
                                        : ''}
                                </P>
                                <P>{note.note}</P>
                            </div>
                        );
                    })}
            </div>

            <Form validationSchema={MemberNotesSchema} submitFn={handleConfirm}>
                <FormElements onCancel={onCancel} disabled={disabled} />
            </Form>
        </Modal>
    ) : null;
};

export default MemberNoteModal;
