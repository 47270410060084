import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { dischargedMembers } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetDischargedMembers = (props, enabled) => {
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}organizations/backoffice/discharge/list`, {
            ...additional,
            params: { ...props }
        }),
        queryKey: [dischargedMembers, props.limit, props.offset],
        enabled
    });
};
export default useGetDischargedMembers;
