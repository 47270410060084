import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { dischargedMembers } from './queryKeys';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
import envVars from '../../../constants/envVars';
const useSaveDischargedMemberNote = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.post(`${envVars.API_COMMERCE_HOSTNAME}organizations/backoffice/discharge/notes`, { ...attributes }, { ...params }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [dischargedMembers] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useSaveDischargedMemberNote;
