import React, { FC } from 'react';
import useTheme from '@hero/branding/theme';
import Divider from '@hero/ui-kit/components/Divider';
import Container from '@hero/ui-kit/layout/Container';
import H from '@hero/ui-kit/typography/H';
import Span from '@hero/ui-kit/typography/Span';
import { useRmaContext } from '../context/RmaProvider';
import { preparedOptionsValue, preparedValue } from '../../utils/prepareValue';
import useGetRmaFulfillmentSerialNumber from '../../utils/useGetRmaFulfillmentSerialNumber';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import { formatDate } from '@hero/hero-utils/date';
import { rmaExchangeSources } from '../../../../utils/rmaExchangeSources';
import RestockingCheck from '../../components/RestockingCheck';
import P from '@hero/ui-kit/typography/P';

interface ExchangeProps {}

export const Exchange: FC<ExchangeProps> = () => {
    const { colors } = useTheme();
    const {
        rma,
        leftColWrapStyles,
        labelStyle,
        valueStyle,
        rmaReasons,
        rmaStatuses,
        formatLocalDateTime,
        returnAddress,
        rightColWrapStyles,
        returnLocations,
        trackingNumber,
        zendeskTicketNumber,
        orderEmail,
        returnStatuses,
        exchangeTrackingNumber,
        returnLabelLink,
        returnedDeviceState,
        replacementBoxRequired,
        replacementBoxTrackingNumber
    } = useRmaContext();

    const {
        id,
        rma_status,
        customer_name,
        reason_code,
        reason_sub_code,
        created_at,
        comments,
        return_location,
        last_updated_at,
        device_serial,
        return_status,
        exchange_device_serial,
        exchange_source,
        exchange_fulfilment_date,
        reprocessing_check
    } = rma ?? {};

    const serialNumber = useGetRmaFulfillmentSerialNumber(rma || undefined);

    const paddingBottom = useComponentPadding({ paddingBottom: 'regular' });

    return (
        <>
            <H role="h4" centered>
                Exchange Detail
            </H>

            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular" gridRowGap="regular">
                <Container styles={leftColWrapStyles}>
                    <P styles={{ ...labelStyle, marginBottom: 0 }} noDefaultMargin>
                        RMA Status
                    </P>
                    <P styles={valueStyle}>{preparedOptionsValue(rma_status, rmaStatuses)}</P>
                </Container>

                <Container styles={{ ...rightColWrapStyles, gridRowStart: 0 }}>
                    <P styles={{ ...labelStyle, marginBottom: 0 }} noDefaultMargin>
                        Created by
                    </P>
                    <P styles={valueStyle}>{rma?.created_by || 'N/A'}</P>
                </Container>
            </Container>

            <P styles={{ ...labelStyle, marginBottom: 0 }} noDefaultMargin>
                Returned Device State
            </P>
            <P styles={{ ...valueStyle, marginBottom: '4.8rem' }}>{returnedDeviceState}</P>
            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular" gridRowGap="regular">
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>RMA #</Span>
                    <Span styles={valueStyle}>{preparedValue(id)}</Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Date Created</Span>
                    <Span styles={valueStyle}>{formatLocalDateTime(created_at)}</Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Member</Span>
                    <Span styles={valueStyle}>{preparedValue(customer_name)}</Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Reason for Exchange</Span>
                    <Span styles={valueStyle}>
                        {reason_code && rmaReasons ? rmaReasons[reason_code].description : '-'}
                    </Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Notes</Span>
                    <Span styles={valueStyle}>{preparedValue(comments)}</Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Restocking check</Span>
                    <RestockingCheck
                        deviceSerial={device_serial}
                        reprocessingCheck={reprocessing_check}
                    />
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 1 }}>
                    <Span styles={labelStyle}>Device Serial</Span>
                    <Span styles={valueStyle}>{preparedValue(device_serial)}</Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 2 }}>
                    <Span styles={labelStyle}>Last Updated At</Span>
                    <Span styles={valueStyle}>{formatLocalDateTime(last_updated_at)}</Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 3 }}>
                    <Span styles={labelStyle}>Email Address</Span>
                    <Span styles={valueStyle}>{preparedValue(orderEmail)}</Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 4 }}>
                    <Span styles={labelStyle}>Sub Reason</Span>
                    <Span styles={valueStyle}>
                        {reason_sub_code && rmaReasons
                            ? rmaReasons[reason_code || 0].sub_codes[reason_sub_code]
                            : '-'}
                    </Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 5 }}>
                    <Span styles={labelStyle}>Zendesk Ticket</Span>
                    {zendeskTicketNumber}
                </Container>
            </Container>
            <Divider styles={{ borderColor: colors.brand.primary.alpha }} />
            <Container
                styles={{ ...paddingBottom }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                gridRowGap="regular"
            >
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Replacement Box Required</Span>
                    <Span styles={valueStyle}>{replacementBoxRequired}</Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 1 }}>
                    <Span styles={labelStyle}>Replacement Box Tracking Number</Span>
                    {replacementBoxTrackingNumber}
                </Container>
            </Container>
            <Divider styles={{ borderColor: colors.brand.primary.alpha }} />
            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular" gridRowGap="regular">
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Return Tracking #</Span>
                    {trackingNumber}
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Return Status</Span>
                    <Span styles={valueStyle}>
                        {preparedOptionsValue(return_status, returnStatuses)}
                    </Span>
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Return Label</Span>
                    {returnLabelLink}
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 1 }}>
                    <Span styles={labelStyle}>Return Location</Span>
                    <Span styles={valueStyle}>
                        {preparedOptionsValue(return_location, returnLocations)}
                    </Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 2 }}>
                    <Span styles={labelStyle}>Return Address</Span>
                    {returnAddress}
                </Container>
            </Container>
            <Divider styles={{ borderColor: colors.brand.primary.alpha }} />
            <Container
                styles={{ ...paddingBottom }}
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                gridRowGap="regular"
            >
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Exchange Tracking #</Span>
                    {exchangeTrackingNumber}
                </Container>
                <Container styles={leftColWrapStyles}>
                    <Span styles={labelStyle}>Exchange Device Serial</Span>
                    <Span styles={{ ...valueStyle, color: colors.brand.primary.alpha }}>
                        {preparedValue(exchange_device_serial || serialNumber)}
                    </Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 1 }}>
                    <Span styles={labelStyle}>Exchange Source</Span>
                    <Span styles={valueStyle}>
                        {exchange_source && rmaExchangeSources[exchange_source]
                            ? rmaExchangeSources[exchange_source]
                            : '-'}
                    </Span>
                </Container>
                <Container styles={{ ...rightColWrapStyles, gridRowStart: 2 }}>
                    <Span styles={labelStyle}>Exchange Fulfillment Date</Span>
                    <Span styles={valueStyle}>
                        {exchange_fulfilment_date ? formatDate(exchange_fulfilment_date) : '-'}
                    </Span>
                </Container>
            </Container>
        </>
    );
};
