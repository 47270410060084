import React from 'react';

import { formatDate, formatShortDate, normalizeDateString } from '@hero/hero-utils/date';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
import Button from '@hero/ui-kit/inputs/Button';
import Container from '@hero/ui-kit/layout/Container';
import A from '@hero/ui-kit/typography/A';
import H from '@hero/ui-kit/typography/H';

import { Detail, DetailLabel, DetailsContainer } from '../../../components/Details';
import FormattedAddress from '../../../components/FormattedAddress';
import envVars from '../../../constants/envVars';
import getActiveDateFormatted from '../../../utils/getActiveDateFormatted';
import NewProviderElection from '../../../views/NewProviderElection';
import getEnrollmentStatusLabel from '../utils/getEnrollmentStatusLabel';
import getMemberFullName from '../utils/getMemberFullName';
import getMemberStatusLabel from '../utils/getMemberStatusLabel';
import OrgMemberReactivation from './Reactivation';
import { EnterpriseCancellationDetails, EnterpriseEnrollment } from '@hero/hero-types';
import RtmElectionInfo from './RtmElectionInfo';
import { DischargedMemberDetails } from '../../Retention/types';
import AlertIcon from '@hero/ui-kit/icons/utility/AlertIcon';
import P from '@hero/ui-kit/typography/P';

type Props = {
    enrollment: EnterpriseEnrollment;
    orgCancellationDetails?: EnterpriseCancellationDetails;
    dischargedMember?: DischargedMemberDetails;
};

const Details: React.FC<Props> = ({ enrollment, orgCancellationDetails, dischargedMember }) => {
    const detailsCancellation = orgCancellationDetails?.cancellation;
    const [openElection, setOpenElection] = React.useState(false);
    const [openRtmMemberElection, setOpenRtmMemberElection] = React.useState(false);

    const dob = enrollment.member?.contact?.date_of_birth || enrollment.contact?.date_of_birth;
    const userEmail = enrollment.member?.contact?.email || enrollment.contact?.email;
    const userId = enrollment.member?.hero_user_id;
    const phoneNumber =
        enrollment.member?.contact?.cell_phone ||
        enrollment.member?.contact?.home_phone ||
        enrollment.contact?.cell_phone ||
        enrollment.contact?.home_phone;
    const activatedAt = enrollment.member?.devices[0]?.activated_at;
    const deviceId = enrollment.member?.devices[0]?.hero_device_id;
    const customerId = enrollment.member?.stripe_customer_id;
    const address = enrollment.member?.contact?.address?.zip
        ? enrollment.member.contact.address
        : enrollment.contact?.address;
    const caregiver = enrollment?.caregiver_contact;

    return (
        <>
            <div style={{ display: 'flex', columnGap: '2rem' }}>
                <DetailsContainer>
                    <DetailLabel strong>Member ID</DetailLabel>
                    <Detail>{enrollment.member?.id}</Detail>
                    <DetailLabel strong>Invitation Code</DetailLabel>
                    <Detail>{enrollment.invite_code}</Detail>
                    <DetailLabel strong>Name</DetailLabel>
                    <Detail>{getMemberFullName(enrollment)}</Detail>
                    <DetailLabel strong>DOB</DetailLabel>
                    <Detail>{dob ? formatShortDate(normalizeDateString(dob, true)) : '-'}</Detail>
                    <DetailLabel strong>Address</DetailLabel>
                    <Detail>
                        <FormattedAddress address={address} />
                    </Detail>
                    <DetailLabel strong>Email</DetailLabel>
                    <Detail>
                        {userEmail ? (
                            userId ? (
                                <A to={`/users/${userId}`}>{userEmail}</A>
                            ) : (
                                userEmail
                            )
                        ) : (
                            '-'
                        )}
                    </Detail>
                    <DetailLabel strong>Phone Number</DetailLabel>
                    <Detail>
                        {phoneNumber ? formatPhoneNumber(phoneNumber, 'international') : '-'}
                    </Detail>
                    <DetailLabel strong>Enrollment Status</DetailLabel>
                    <Detail>{getEnrollmentStatusLabel(enrollment.status)}</Detail>
                    {enrollment.member?.status === 'CANCELLED' ? (
                        <>
                            <DetailLabel strong>Cancelled by</DetailLabel>
                            <Detail>{detailsCancellation?.cancelled_by || 'N/A'}</Detail>
                        </>
                    ) : null}
                    <DetailLabel strong>Membership Status</DetailLabel>
                    <Detail>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '4rem' }}>
                            <div>
                                {enrollment.member?.status
                                    ? getMemberStatusLabel(enrollment.member.status)
                                    : '-'}
                            </div>
                            {enrollment.member?.status === 'CANCELLED' ? (
                                <OrgMemberReactivation
                                    email={userEmail}
                                    firstName={enrollment.contact?.first_name || undefined}
                                    lastName={enrollment.contact?.last_name || undefined}
                                    externalDeviceSerial={
                                        enrollment.member?.devices[0]?.device_serial
                                    }
                                    memberId={enrollment.id}
                                    organizationId={enrollment.organization.id}
                                />
                            ) : null}
                        </div>
                    </Detail>
                    <DetailLabel strong>Active Date</DetailLabel>
                    <Detail>{getActiveDateFormatted(activatedAt)}</Detail>
                    <DetailLabel strong>Device ID</DetailLabel>
                    <Detail>
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '4rem' }}>
                            <div>
                                {deviceId ? <A to={`/devices/${deviceId}`}>{deviceId}</A> : '-'}
                            </div>
                            {dischargedMember?.is_discharged &&
                            dischargedMember.discharge_details?.block_at_utc ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: '1rem'
                                    }}
                                >
                                    <AlertIcon size="small" />

                                    <P
                                        noDefaultMargin
                                        styles={{ color: 'red' }}
                                        size="small"
                                        strong
                                    >
                                        {`Blocked on ${formatDate(
                                            normalizeDateString(
                                                dischargedMember.discharge_details.block_at_utc
                                            )
                                        )}`}
                                    </P>
                                </div>
                            ) : null}
                        </div>
                    </Detail>
                    <DetailLabel strong>Customer ID</DetailLabel>
                    <Detail>
                        {customerId ? (
                            <A to={`${envVars.STRIPE_DASHBOARD_BASE_URL}/customers/${customerId}`}>
                                {customerId}
                            </A>
                        ) : (
                            '-'
                        )}
                    </Detail>
                    <DetailLabel strong>RTM election info</DetailLabel>
                    <Detail>
                        <Button
                            noDefaultMargin
                            size="small"
                            onClick={() => setOpenRtmMemberElection(true)}
                        >
                            Show
                        </Button>
                    </Detail>
                </DetailsContainer>
                <div>
                    <Container
                        gridTemplateColumns={'1fr 1fr'}
                        gridColumnGap="regular"
                        styles={{ marginBottom: '2.4rem' }}
                    >
                        <H role="h6">Caregiver Contact Info</H>
                        {enrollment?.member?.in_election_from &&
                            !enrollment?.member?.election_completed && (
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        setOpenElection(true);
                                    }}
                                    styles={{
                                        marginTop: '-1.5rem',
                                        lineHeight: '5rem',
                                        height: '5rem'
                                    }}
                                >
                                    Elect New Provider
                                </Button>
                            )}
                    </Container>
                    <Container
                        gridTemplateColumns={'1fr 1fr'}
                        gridColumnGap="regular"
                        styles={{ marginBottom: '2.4rem' }}
                    >
                        <DetailLabel strong>Caregiver First Name</DetailLabel>
                        <Detail>{caregiver?.first_name || '-'}</Detail>
                    </Container>
                    <Container
                        gridTemplateColumns={'1fr 1fr'}
                        gridColumnGap="regular"
                        styles={{ marginBottom: '2.4rem' }}
                    >
                        <DetailLabel strong>Caregiver Last Name</DetailLabel>
                        <Detail>{caregiver?.last_name || '-'}</Detail>
                    </Container>
                    <Container
                        gridTemplateColumns={'1fr 1fr'}
                        gridColumnGap="regular"
                        styles={{ marginBottom: '2.4rem' }}
                    >
                        <DetailLabel strong>Caregiver Email</DetailLabel>
                        <Detail>{caregiver?.email || '-'}</Detail>
                    </Container>
                    <Container
                        gridTemplateColumns={'1fr 1fr'}
                        gridColumnGap="regular"
                        styles={{ marginBottom: '2.4rem' }}
                    >
                        <DetailLabel strong>Caregiver Cell Phone</DetailLabel>
                        <Detail>{caregiver?.cell_phone || '-'}</Detail>
                    </Container>
                    <Container
                        gridTemplateColumns={'1fr 1fr'}
                        gridColumnGap="regular"
                        styles={{ marginBottom: '2.4rem' }}
                    >
                        <DetailLabel strong>Caregiver Home Phone</DetailLabel>
                        <Detail>{caregiver?.home_phone || '-'}</Detail>
                    </Container>
                </div>
            </div>

            {openElection && (
                <NewProviderElection
                    externalControls={[openElection, setOpenElection]}
                    enrollment={enrollment}
                />
            )}
            {openRtmMemberElection && enrollment.member?.id && (
                <RtmElectionInfo
                    member_id={enrollment.member.id}
                    externalControls={[openRtmMemberElection, setOpenRtmMemberElection]}
                />
            )}
        </>
    );
};

export default Details;
