import React from 'react';

import { TableColumn } from '@hero/ui-kit/tables/Table';
import MenuIcon from '@hero/ui-kit/icons/utility/MenuIcon'; // Nothing better for now...

import Link from '../../../../components/Link';
import ActionButton from '../../../../components/ActionButton';
import { DischargeMember } from '../../types';
import P from '@hero/ui-kit/typography/P';
import { formatDateTime, normalizeDateString } from '@hero/hero-utils/date';

export type ActionHandler = (memberId: number) => void;

type Render = TableColumn<DischargeMember>['render'];
type GetActionRender = (actionHandler: ActionHandler) => Render;

const emailRender: Render = ({ member_id, org_id, admin_email }) => (
    <Link to={`/organizations/${org_id}/member/${member_id}`}>{admin_email || '(no email)'}</Link>
);

const reasonRender: Render = ({ discharge_reason }) => (
    <P noDefaultMargin centered>
        {`${discharge_reason[0].toUpperCase()}${discharge_reason.slice(1).toLowerCase()}`.replaceAll(
            '_',
            ' '
        )}
    </P>
);

const orgRender: Render = ({ org_id, org_name }) => (
    <Link to={`/organizations/${org_id}/members`}>{org_name}</Link>
);

const getNoteRender: GetActionRender = (actionHandler) => (member) => (
    <ActionButton
        icon={MenuIcon}
        onClick={() => actionHandler(member.member_id)}
        alt={member.notes?.length ? 'Edit Note' : 'Add Note'}
        variant={member.notes?.length ? 'primary' : 'secondary'}
    />
);

type GetTableColumns = (noteActionHandler: ActionHandler) => TableColumn<DischargeMember>[];
const getTableColumns: GetTableColumns = (noteActionHandler) => [
    {
        colKey: 'member_name',
        name: 'Admin/Member',
        align: 'left',
        headAlign: 'left',
        render: emailRender
    },
    {
        colKey: 'org_name',
        name: 'Discharged from',
        render: orgRender
    },
    {
        colKey: 'discharge_requested_at',
        name: 'Discharged on',
        render: (member) => (
            <P noDefaultMargin centered>
                {member.discharge_requested_at
                    ? formatDateTime(normalizeDateString(member.discharge_requested_at))
                    : ''}
            </P>
        )
    },
    {
        colKey: 'block_at_utc',
        name: 'Device\nBlocked on',
        render: (member) => (
            <P noDefaultMargin centered>
                {member.block_at_utc
                    ? formatDateTime(normalizeDateString(member.block_at_utc))
                    : ''}
            </P>
        )
    },
    {
        colKey: 'cancellation_deadline',
        name: 'Cancellation\nDeadline',
        render: (member) => (
            <P noDefaultMargin centered>
                {member.cancellation_deadline
                    ? formatDateTime(normalizeDateString(member.cancellation_deadline))
                    : ''}
            </P>
        )
    },
    {
        colKey: 'discharge_reason',
        name: 'Discharge\nReason',
        render: reasonRender
    },
    {
        colKey: 'notes',
        name: 'Notes',
        render: getNoteRender(noteActionHandler)
    }
];

export default getTableColumns;
